





































import Vue from 'vue';
import { CopyToClipBoard } from '@/assets/mixins';

export default Vue.extend({
  name: 'Portfolio-Profile-Component',
  props: {
    landlord: {
      type: Object,
      required: true,
      default: () => {
        return {
          id: '',
          image: '',
          firstname: '',
          surname: '',
          description: '',
        };
      },
    },
  },
  data: () => {
    return {
      urlCopyStatus: false,
      urlCopyMessage: 'Copy',
    };
  },
  computed: {
    url (): string {
      return window.location.origin + '/portfolio/' + this.landlord.id;
    },
    fullname (): string {
      if (this.landlord.firstname && this.landlord.surname) {
        return this.landlord.firstname +  ' ' + this.landlord.surname;
      }
      return '';
    },
    avatar (): string {
      return this.$store.state.avatar;
    },
  },
  // Methods
  methods: {
    copyURL () {
      this.urlCopyStatus = CopyToClipBoard(this.url);
      if (this.urlCopyStatus) {
        this.urlCopyMessage = 'Copied to Clipboard';
      } else {
        this.urlCopyMessage = 'Ooops, try again...';
        // Reset button after 5 seconds
        setTimeout(() => {
          this.urlCopyMessage = 'Copy';
        }, 2500);
      }
    },
  },
});
