











































































































import Vue from 'vue';
import CloseBtn from '@/blueprint/components/ui/CloseButton.vue';
import DeleteBtn from '@/blueprint/components/ui/DeleteButton.vue';

import gql from 'graphql-tag';
import { GQLTagRequestObject } from '@/assets/clients/gqlClient';
import { Blob } from '@lordly/models2/interfaces';
import { Property } from '@lordly/models2/interfaces/models/Property';
import { API } from '@lordly/models2/interfaces/gql';
import { ActionPayload } from '@/interfaces';
import { MutatePortfolioPayload } from '@/store/landlord/mutations';
import { LandlordState } from '../../../store/landlord/state';

export default Vue.extend({
  name: 'Landlord-Property-Component',
  components: { DeleteBtn, CloseBtn },
  props: {
    property: {
      type: Object as () => Partial<Property>,
      required: true,
      default: () => {
        return {
          id: null,
          images: [],
          address: {
            line1: '',
            city: '',
            partition: '',
          },
          available: false,
          meta: {
            createdOn: '',
            lastUpdatedOn: '',
          },
          tenancyCount: 0,
        };
      },
    },
    propertyIndex: {
      type: Number,
      required: true,
      default: -1,
    },
  },
  data () {
    return {
      showOverlay: false,
      firstImage: '',
      firstImageError: false,
      observer: {} as IntersectionObserver,
      updateLoading: false,
      obsoleteLoading: false,
      availabilityLoading: false,
    };
  },
  computed: {
    availableToRent (): string {
      if (this.property.available) {
        return 'Y';
      } else {
        return 'N';
      }
    },
  },
  mounted () {
    this.createIntersectionObserver();
  },
  methods: {
    async obsoleteProperty () {
      this.obsoleteLoading = true;
      try {
        // Create query
        const query: GQLTagRequestObject = gql`
          mutation ($id: String!, $partition: String!, $property: IPropertyInfo!) {
            UpdateProperty (
              input: {
                id: $id,
                partition: $partition,
                property: $property
              }
            ) {
              meta {
                __typename
                ... on FullMetadata {
                obsolete
                obsoletedOn
                obsoletedBy
                }
              }
            }
          }
        `;
        // Create payload
        const payload: API.UpdatePropertyInput = {
          id: this.property.id!,
          partition: this.property.address!.partition,
          property: {
            meta: {
              obsolete: true,
            } as any,
          },
        };
        // Send Request
        const response: Partial<Property> = await this.$gql.Mutation('UpdateProperty', query, payload);
        // Remove from the portfolio array
        const mutationPayload: MutatePortfolioPayload = {
          index: String(this.propertyIndex),
          body: response,
        };
        this.$store.commit('landlord/MutatePortfolioProperty', mutationPayload);
      } catch (e) {
        console.error(e);
      }
      this.obsoleteLoading = false;
    },
    async toggleAvailability () {
      this.availabilityLoading = true;
      try {
        // Create query
        const query: GQLTagRequestObject = gql`
          mutation ($id: String!, $partition: String!, $property: IPropertyInfo!) {
            UpdateProperty (
              input: {
                id: $id,
                partition: $partition,
                property: $property
              }
            ) {
              available
            }
          }
        `;
        // Create payload
        const payload: API.UpdatePropertyInput = {
          id: this.property.id!,
          partition: this.property.address!.partition,
          property: {
            available: !this.property.available,
          },
        };
        // Send Request
        const response: Partial<Property> = await this.$gql.Mutation('UpdateProperty', query, payload);
        // Update the property in the portfolio array
        const mutationPayload: MutatePortfolioPayload = {
          index: String(this.propertyIndex),
          body: response,
        };
        this.$store.commit('landlord/MutatePortfolioProperty', mutationPayload);
      } catch (e) {
        console.error(e);
      }
      this.availabilityLoading = false;
    },
    constructImages () {
      // Conver images to JSON array
      const tempImageArray: Blob[] = this.property.images ? this.property.images : [];
      if (tempImageArray.length > 0) {
        if (tempImageArray[0]) {
          const imageDOMElement: HTMLImageElement = this.$refs.thumbnail as HTMLImageElement;
          // Reset onerror observer for card
          imageDOMElement.onerror = null;
          // Generate images URL
          const imageURL: string = tempImageArray[0].url;
          // Push generated image URL to images array
          this.firstImage = imageURL;
          this.firstImageError = false;
          // Add on error observer to card incase image fails
          this.$nextTick().then(() => {
            // Scroll to the bottom
            imageDOMElement.addEventListener('error', () => {
              this.loadPlaceholderImage();
            }, { once: true});
          });
        }
      } else {
        this.loadPlaceholderImage();
      }
    },
    loadPlaceholderImage () {
      this.firstImage = window.location.origin + '/img/app/icons/placeholder-property-thumbnail.png';
      this.firstImageError = true;
      // Remove event listener to handle possible infinite loops
      (this.$refs.thumbnail as HTMLImageElement).onerror = null;
    },
    createIntersectionObserver () {
      // Create intersection observers for lazy loading
      const threshold: number = 0.1;
      const options: Record<string, any> = {
        root: this.$parent.$parent.$refs.layoutBody,
        rootMargin: '8px',
        threshold,
      };
      // Create observer
      this.observer = new IntersectionObserver((entry, observer) => {
        // Check as when instantiated the function fires straight away
        if (entry[0].intersectionRatio > threshold) {
          this.constructImages();
          this.observer.unobserve(this.$refs.portfolioCard as Element);
          delete this.observer; // Mark for GC to reclaim memory
        }
      }, options);
      // Tell observer to listen
      this.observer.observe(this.$refs.portfolioCard as Element);
    },
    async updateProperty () {
      this.updateLoading = true;
      try {
        // Consturct action payload
        const payload: ActionPayload = {
          component: this,
          id: this.property.id,
          partition: this.property.address!.partition,
        };
        const property: Partial<Property> = await this.$store.dispatch('landlord/actionGetPropertyForUpdate', payload);
        // Generate extra form info
        const extras: LandlordState['form']['extras'] = {
          id: this.property.id!,
          partition: this.property.address!.partition,
          idx: this.propertyIndex,
        };
        // Attach to payload
        const updateFormPayload: Partial<Property> & { extras: LandlordState['form']['extras'] } = property as any;
        updateFormPayload['extras'] = extras;
        // Sync form
        this.$store.commit('landlord/MutateForm', updateFormPayload);
        // Reroute to update
        this.$router.push('/landlord/portfolio/update');
      } catch (e) {
        console.error(e);
      }
      this.updateLoading = false;
    },
    async showTenancies () {
      const url: string = 'portfolio/tenancies/' + this.property.id + '/' + this.property.address!.partition;
      this.$router.push(url);
    },
  },
});
