

















import Vue from 'vue';

export default Vue.extend({
  name: 'UI-DeleteBtn-Component',
  props: {
    rounded: {
      type: Boolean,
      required: false,
      default: false,
    },
    notFlat: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '1rem',
    },
  },
  data () {
    return {
      btnClicked: false,
      clearTimeout: -1,
    };
  },
  methods: {
    invokeDelete () {
      if (!this.btnClicked) {
        this.btnClicked = true;
        // Timeout confirmation window - 2.5 sec
        this.clearTimeout = window.setTimeout(() => {
          this.btnClicked = false;
        }, 2500);
      } else {
        window.clearTimeout(this.clearTimeout);
        this.$emit('delete');
        this.btnClicked = false;
      }
    },
  },
});
