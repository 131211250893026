










































import Vue from 'vue';
import PortfolioProfile from '@/blueprint/components/shared/PortfolioProfile.vue';
import Property from '@/blueprint/components/landlord/Property.vue';

import gql from 'graphql-tag';
import { GQLTagRequestObject } from '@/assets/clients/gqlClient';
import { LandlordState, IndexedProperty } from '@/store/landlord/state';
import { ComputePagination } from '@/assets/mixins';

export default Vue.extend({
  name: 'Portfolio-Share',
  components: {
    profile: PortfolioProfile,
    property: Property,
  },
  data () {
    return {
      landlordStateWatcher: () => { return; },
      landlord: {
        id: '',
        image: '',
        firstname: '',
        surname: '',
        description: '',
      },
      portfolio: {
        properties: [] as IndexedProperty[],
        display: [] as IndexedProperty[],
        currentPage: 1,
        totalPages: 1,
        pageSize: 12,
      },
    };
  },
  computed: {
    landlordId: {
      get (): string {
        return (this.$store.state.landlord as LandlordState).user.id;
      },
    },
    landlordState (): LandlordState {
      return this.$store.state.landlord as LandlordState;
    },
    availablePropertes (): IndexedProperty[] {
      return this.$store.getters['landlord/availableProperties'];
    },
  },
  created () {
    // Populate landlord information
    this.syncUserInfo();
    // BugFix: Fix state not syncing on hard refresh
    this.landlordStateWatcher = this.$watch('landlordState', () => {
      // Sync
      this.syncUserInfo();
      // Destory after sync
      this.landlordStateWatcher();
    }, { deep: true });
  },
  beforeDestroy () {
    // Destory watcher just incase
    this.landlordStateWatcher();
  },
  watch: {
    availablePropertes () {
      this.syncProperties();
    },
  },
  methods: {
    updatePagination () {
      ComputePagination(this.portfolio , 'properties');
    },
    syncProperties () {
      // Populate portfolio information
      this.portfolio.properties = this.availablePropertes;
      // Compute pagination
      ComputePagination(this.portfolio, 'properties');
    },
    syncUserInfo () {
      // Populate landlord information
      const userInfo: LandlordState['user'] = this.landlordState.user;
      // Map
      this.landlord.id = userInfo.id;
      this.landlord.image = (userInfo.image && userInfo.image.length > 0) ? userInfo.image[0].url : '',
      this.landlord.firstname = userInfo.firstname;
      this.landlord.surname = userInfo.surname;
      this.landlord.description = userInfo.description ? userInfo.description : '';
      this.syncProperties();
    },
  },
});
